export default {
    namespaced: true,// 为当前模块开启独立的命名空间
    state: {
        // Textindex：侧边高亮的变量
        Textindex: 'homePage',
        tag_index: 'plain',
        // 首页标签数据源
        el_homepage: [
            { type: '', label: 'homePage' },
        ],
        // 小标签数据源
        el_homepage2: [
            { type: '', label: 'homePage' }
        ],
        // 用户登录信息
        uesrlogin1: [
            {
                name: 'admin',
                label: '超级管理员',
                password: '123456',
                date: '2023.4.5',
                addsser: '成都'
            }, {
                name: 'admin1',
                label: 'svip用户',
                password: '123456',
                date: '2023.4.5',
                addsser: '成都'
            }, {
                name: 'admin2',
                label: '普通用户',
                password: '123456',
                date: '2023.4.5',
                addsser: '成都'
            }
        ],
        uesrlogin: "",
        shopArr: [],



    },
    getters: {

    },
    mutations: {
        changemath(state, val) {
            // 侧边高亮显示的vuex变量
            state.Textindex = val
            // 过滤一下我的侧边栏数据，以控制小标签的数量
            const isCharEqual = state.el_homepage2.some(item => item.label.includes(val));
            // vuex里面没有此数据且传过来的数据不是首页，便可以向vuex中添加我想要的数据
            if (!isCharEqual && val != '') {
                let arr = {
                    // 1.type是elementui的标签类型，''是默认类型
                    // 2.label值是小标签的名字
                    type: '', label: val
                }
                if (arr.label != '') {
                    // 添加到vuex
                    state.el_homepage2.push(arr)
                }
            }
        },
        removarr(state, val) {
            // 通过传值的方式进行删除vuex中的数据，val是我点击的小标签删除按钮的下标值，这里正好对应我所添加进来的数据的下标
            state.el_homepage2.splice(val, 1)
        },
        changeSun(state, val) {
            state.uesrlogin = val
        },
        shopcjh(state, val) {
            state.shopArr = val
        },
    },
    atcions: {

    },
    modules: {

    },


}